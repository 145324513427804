// Dependencies
import React from "react";
import { graphql, Link } from "gatsby";
import PropTypes from "prop-types";
// Hooks
// import useMouse from "../hooks/useMouse";
// Components
import Layout from "../components/Layout";
import LinkType from "../components/LinkType";
import Img from "../components/Img";

// This is so we can have an activity with all the fields present 
// for gatsby to get a complete picture of the model
const PLACEHOLDER_ENTRY_ID = '56ui0lLbov8iCzyTc3tNCD';

const ActivityLink = ({ title, slug, type, dateline, thumbnail, slides, information, externalUrl, contentful_id }) => {
	return (
		<LinkType 
			className={`activity-link guttersx2 inner ${(slides || information || externalUrl) ? 'rollover' : ''}`}
			to={(slides || information) ? `/activity/${type.slug}/${slug}` : null} 
			url={externalUrl}
		>
			<div className="text">
				<h5>{dateline}</h5>
				<h3 className="">
					{title}
				</h3>
			</div>
			<div className="image">
				<div className="thumbnail">
					{thumbnail && <Img {...thumbnail} />}
				</div>
			</div>
		</LinkType>
	)
}

const ActivityCategoryTemplate = ({ data, pageContext }) => {

	const { title, filterByType, filterByYear } = pageContext;
	const activities = data.allContentfulActivity.nodes;

  return (
    <Layout title={title} headerTitle={title}>
			<section className="activity-index">
				{activities.map((entry, index) => {
					const filter = (entry.type && entry.type.slug === filterByType || entry.year && entry.year.year === filterByYear);
					return (
						filter && <ActivityLink {...entry} key={`activity-${entry.id}-${index}`} />
					)
				})}
			</section>
    </Layout>
  )
}

ActivityCategoryTemplate.propTypes = {
  data: PropTypes.object.isRequired
}

export default ActivityCategoryTemplate

export const ActivityCategoryTemplatePageQuery = graphql`
  query ActivityCategoryTemplateQuery {
		allContentfulActivity(
			sort: {fields: date, order: DESC}
			filter: {contentful_id: {ne: "56ui0lLbov8iCzyTc3tNCD"}}
		) {
			nodes {
				id
				contentful_id
				title
				slug
				type {
					slug
				}
				year {
					year
				}
				dateline
				thumbnail {
					fluid(maxWidth: 500) {
						...GatsbyContentfulFluid_noBase64
					}
				}
				slides {
					id
				}
				information {
					raw
				}
				externalUrl
			}
		}
  }
`
